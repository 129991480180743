export const tableData = [
  {
    id: 1,
    name: "Item 1",
    city: "Amsterdam",
  },
  {
    id: 2,
    name: "Item 2",
    city: "Berlin",
  },
  {
    id: 3,
    name: "Item 3",
    city: "New York",
  },
  {
    id: 4,
    name: "Item 4",
    city: "Tokio",
  },
  {
    id: 5,
    name: "Item 5",
    city: "Brussels",
  },
  {
    id: 6,
    name: "Item 6",
    city: "Barcelona",
  },
];
